const Settings = {
    ghPageCustomDomain: "ecare-aws.revtel.tech",
    webUrl: "https://merck-hms.revtel.tech",
    apiUrl: "https://ecare-hk-api.revtel2.com",
    workerPort: 12346,
    apiPrefix: "",
    SSR: true,
    cloudinaryUserId: "dfpqu00k2",
    debug: false,
    paypalSandboxEnv: true,
    paypalSandboxClientId: "AYCyvEJoKJ3378r-X2IozaB2HGknq7jd8U5aZjqF9jSl-Okj1kkIVNTpqBDej99zV4A2JQWzSjak7mOn",
    paypalProductionClientId: "???",
    facebookAppId: "188397608698732",
    googleClientId: "115128487422-bs5m8q90u3g4ata8lb9ujorusgpqhefj.apps.googleusercontent.com",
};

module.exports = Settings;

