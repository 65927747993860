import { createStore, applyMiddleware, compose } from 'redux';
import reducers from './Reducers';
import products from '../content/products.json';

let logger = store => next => action => {
  if (!console.group) {
    return next(action);
  }

  console.group(action.type);
  console.log('payload', action.payload);
  console.log('state before', store.getState());
  let result = next(action);
  console.log('state after', store.getState());
  console.groupEnd(action.type);
  return result;
};

function configureStore(initialState = {}) {
  const middlewares = [logger];

  const enhancers = [applyMiddleware(...middlewares)];

  const store = createStore(reducers, initialState, compose(...enhancers));

  return store;
}

// global store, only used in client-render
let store;

let initialState = {
  product: {
    list: products,
    map: products.reduce((acc, v) => ({ ...acc, [v.id]: v }), {}),
  },
};

function getStore() {
  if (!store) {
    console.log('initialize redux store');
    store = configureStore(initialState);
  }

  return store;
}

export { configureStore, getStore };
