import React, { Component } from "react";
import styled from "styled-components";
import Theme from "../Theme";

class Footer extends Component {
  render() {
    let { extraStyle = "" } = this.props;
    console.log("theme", Theme);
    return (
      <Wrapper extraStyle={extraStyle}>
        <Content>
          <CompanyName>
            © 2020 Copyright Merck Limited. All rights reserved.
          </CompanyName>
        </Content>
      </Wrapper>
    );
  }
}

let Wrapper = styled.div`
  background-color: ${Theme.colors.pink};
  width: 100vw;
  padding: 20px 10px;
  color: white;
  ${props => props.extraStyle};
`;
let Content = styled.div`
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;
let CompanyName = styled.div`
  width: 400px;
  font-size: 13px;
  color: white;
  text-align: center;
  margin: 0;
`;
let CompanyInfo = styled.div`
  width: 400px;
  color: white;

  @media screen and (max-width: 600px) {
    width: 300px;
  }
`;

export default Footer;
