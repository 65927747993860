require('whatwg-fetch');
const nodeFetch = require('node-fetch');
const Constant = require('./Domain/constants');

const universalFetch = (function getCorrectFetchImpl() {
  try {
    return fetch;
  } catch (ex) {
    return nodeFetch;
  }
})();

function addTailingSlashForPath(url) {
  let [path, query] = url.split('?');
  if (path[path.length - 1] !== '/') {
    path += '/';
  }
  return `${path}${query ? '?' + query : ''}`;
}

class _ApiProxy {
  constructor() {
    this._commonHeader = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    this._apiToken = null;
  }

  _buildEndpoint({ path, withHost = false }) {
    if (withHost) {
      return path;
    }

    // `withHost` means we're sending req to our own API server,
    // which is DRF based and we hope to see tailing slash
    path = addTailingSlashForPath(path);
    return path ? `${Constant.apiUrl}${path}` : null;
  }

  _buildHeaders({ extraHeaders, skipCommonHeader = false, withToken = true }) {
    let authHeader = (this._apiToken && withToken)
      ? { Authorization: `Token ${this._apiToken}` }
      : {};
    if (skipCommonHeader) {
      return authHeader;
    }
    return {
      ...this._commonHeader,
      ...authHeader,
    };
  }

  setToken(token) {
    this._apiToken = token;
  }

  get({ path, extraHeaders = {}, withHost = false, withToken = true }) {
    return universalFetch(this._buildEndpoint({ path, withHost }), {
      method: 'GET',
      headers: this._buildHeaders({ extraHeaders, withToken }),
    }).then(response => {
      if (`${response.status}`[0] === '2') {
        return response.json();
      }
      return Promise.reject({ status: response.status });
    });
  }

  post({ path, extraHeaders = {}, data, withHost = false }) {
    return universalFetch(this._buildEndpoint({ path, withHost }), {
      method: 'POST',
      headers: this._buildHeaders({ extraHeaders }),
      body: JSON.stringify(data),
    }).then(response => {
      if (`${response.status}`[0] === '2') {
        return response.json();
      }
      return Promise.reject({ status: response.status });
    });
  }

  put({ path, extraHeaders = {}, data, withHost = false }) {
    return universalFetch(this._buildEndpoint({ path, withHost }), {
      method: 'PUT',
      headers: this._buildHeaders({ extraHeaders }),
      body: JSON.stringify(data),
    }).then(response => {
      if (`${response.status}`[0] === '2') {
        return response.json();
      }
      return Promise.reject({ status: response.status });
    });
  }

  delete({ path, extraHeaders = {}, withHost = false }) {
    return universalFetch(this._buildEndpoint({ path, withHost }), {
      method: 'DELETE',
      headers: this._buildHeaders({ extraHeaders }),
    }).then(response => {
      if (`${response.status}`[0] === '2') {
        return response.json();
      }
      return Promise.reject({ status: response.status });
    });
  }

  formPost({ path, extraHeaders = {}, formData, withHost = false }) {
    return universalFetch(this._buildEndpoint({ path, withHost }), {
      method: 'POST',
      headers: this._buildHeaders({ extraHeaders, skipCommonHeader: true }),
      body: formData,
    }).then(response => {
      if (`${response.status}`[0] === '2') {
        return response.json();
      }
      return Promise.reject({ status: response.status });
    });
  }

  getImage(imageUrl) {
    return this._buildEndpoint({ path: imageUrl });
  }
}

module.exports = new _ApiProxy();
