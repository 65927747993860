import React, { Component } from "react";
import styled from "styled-components";
import "./index.css";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import MuiWrapper from "./MuiWrapper";
import { checkMobile, normalizePath } from "./Utils/LocationUtil";

class Layout extends Component {
  render() {
    let { location } = this.props;

    function hasFooter(path) {
      if (path.indexOf("/record") === 0) {
        return false;
      } else if (path.indexOf("/create-record") === 0) {
        return false;
      } else if (path.indexOf("/edit-record") === 0) {
        return false;
      } else if (path.indexOf("/profile") === 0) {
        return false;
      } else if (path.indexOf("/videos") === 0) {
        return false;
      } else if (path.indexOf("/faq") === 0) {
        return false;
      } else if (path.match(/^\/$/)) {
        return true;
      }
      return true;
    }

    function hasNavbarLogo(path) {
      if (path.indexOf("/videos") === 0) {
        return true;
      }
      return false;
    }
    function hasNavbar(path) {
      if (path.match(/^\/$/)) {
        return false;
      }
      return true;
    }

    let navbarlogo = hasNavbarLogo(normalizePath(location.pathname));
    let navbar = hasNavbar(normalizePath(location.pathname));
    let footer = hasFooter(normalizePath(location.pathname));
    let inMobileApp = checkMobile(location);

    return (
      <MuiWrapper>
        <Layout.Wrapper paddingTop={!inMobileApp && navbar}>
          {navbar ? !inMobileApp && <Navbar logo={navbarlogo} /> : null}

          <Layout.Content>{this.props.children}</Layout.Content>

          {footer && !inMobileApp && <Footer />}
        </Layout.Wrapper>
      </MuiWrapper>
    );
  }

  _normalizePath = path => {
    if (path.slice(0, 2) === "//") {
      path = path.slice(1);
    }

    if (path[path.length - 1] !== "/") {
      path += "/";
    }

    return path;
  };
}

Layout.Wrapper = styled.div`
  min-height: calc(100vh);
  background-color: white;
  display: flex;
  flex-direction: column;
  ${props => (props.paddingTop ? "padding-top: 60px;" : "")}
`;

Layout.Content = styled.div`
  flex: 1;
  align-self: stretch;
`;

export default Layout;
