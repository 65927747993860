import React from "react";
import styled from "styled-components";
import {
  CircularProgress,
  RaisedButton,
  FlatButton,
  IconButton,
  DropDownMenu,
  MenuItem,
  RadioButton
} from "material-ui";

export const BgImage = styled.div`
  background-color: ${props => props.color || "#d0d0d0"};
  background-image: url(${props => props.image});
  background-size: ${props => props.size || "cover"};
  background-repeat: ${props => props.repeat || "no-repeat"};
  background-position: ${props => props.position || "center"};
  width: 100%;
  height: 100%;
  ${props => props.extraCss};
`;

export const Container = styled.div`
  ${props => props.extraCss};
`;

export const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${props => props.extraCss};
`;

export const MaxWidth = styled.div`
  max-width: ${props => props.width}px;
  margin: ${props => props.margin || "0 auto"};
  ${props => props.extraCss};
`;

export const Row = styled.div`
  display: flex;
  align-items: ${props => props.align || "stretch"};
  justify-content: ${props => props.justify || "flex-start"};
  flex-wrap: ${props => (props.wrap ? "wrap" : "nowrap")};
  ${props => props.extraCss};
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => props.align || "stretch"};
  justify-content: ${props => props.justify || "flex-start"};
  ${props => props.extraCss};
`;

export const Input = styled.input`
  padding: 5px;
  font-size: 16px;
  width: 100%;
  outline: none;
  border: 1px solid #ddd;

  &:focus {
    outline: 1px solid #06bcd5;
    box-shadow: ${props => (props.inset ? "inset" : "")} 0 0 0px 2px
      rgba(6, 188, 213, 0.5);
    outline-offset: -1px;
  }

  ${props => props.extraCss};
`;

export const FixRatioImage = ({
  image,
  width = "100%",
  heightRatio = "56.25%",
  extraStyle
}) => (
  //heightRatio: (9: 16 = 56.25%) (3 : 4 = 75%)
  <div style={{ width: width, ...extraStyle }}>
    <div style={{ position: "relative", top: 0, paddingTop: heightRatio }}>
      <BgImage image={image} extraCss="position: absolute; top: 0px;" />
    </div>
  </div>
);

export const MuiSelect = props => {
  return (
    <DropDownMenu
      {...props}
      onChange={(event, index, value) => {
        // to fit in unstated form usage
        console.log(value);
        props.onChange({ ...event, target: { value: value } });
      }}
    >
      {props.children}
    </DropDownMenu>
  );
};

export let NativeButton = styled.button`
  display: inline-block;
  border: none;
  padding: 8px 12px;
  margin-left: 10px;
  text-decoration: none;
  background: ${props => props.color || "teal"};
  color: #ffffff;
  font-family: sans-serif;
  font-size: ${props => props.fontSize || "20px"};
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;

  & :hover {
    font-weight: 900;
    background: ${props => props.color || "teal"};
  }

  & :focus {
    background: ${props => props.color || "teal"};
    outline: none;
  }

  & :active {
    transform: scale(0.8);
  }

  & :disabled {
    background-color: #cccccc;
    color: #666666;
  }

  ${props => props.extraCss || ""}
`;
export class AnimateButton extends React.Component {
  render() {
    return (
      <AnimateButton.Btn {...this.props}>
        <div>{this.props.children}</div>
      </AnimateButton.Btn>
    );
  }
  static Btn = styled.div`
    background-color: ${props => props.bgColor || "#555"};
    border-radius: 10px;
    color: white;
    padding: 10px 40px;
    font-size: 14px;
    cursor: default;
    position: relative;
    overflow: hidden;
    & > div {
      position: relative;
    }
    & ::before {
      content: "";
      width: 100%;
      height: 0;
      position: absolute;
      left: 0;
      top: 0;
      background-color: ${props => props.hoverBgColor || "#777"};
      transition: height 0.1s ease-out;
    }
    &:hover ::before {
      height: 100%;
      transition: all 0.1s ease-in;
    }
  `;
}

export const Spinner = CircularProgress;
export const Button = RaisedButton;
export { FlatButton, IconButton, MenuItem, RadioButton };
