import React from "react";
import styled from "styled-components";
import Link from "./Link";
import * as Widget from "./Widget";
import * as Icon from "./Icon";

class Navbar extends React.Component {
  render() {
    let { logo } = this.props;

    return (
      <Navbar.Wrapper>
        <Navbar.Content>
          <Link to="/" className="site-mark">
            {logo ? (
              <img className="site-logo" src="/images/pages/logo/saizen.png" />
            ) : (
              <h2 className="site-title">Merck</h2>
            )}
          </Link>

          <div className="navs">
            {/* <Link to="/profile" className="nav-item">
              <Icon.Person color="white" />
            </Link> */}
            <Link to="/faq" className="nav-item">
              FAQ
            </Link>
          </div>
        </Navbar.Content>
      </Navbar.Wrapper>
    );
  }
}

Navbar.Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: teal;
  padding: 0px 20px;
  z-index: 1;
`;

Navbar.Content = styled.div`
  margin: 0 auto;
  padding: 10px;
  max-width: 1024px;
  height: 60px;
  display: flex;
  align-items: center;

  & > .site-mark {
    & > .site-title {
      color: white;
      margin-bottom: 0px;
    }

    & > .site-logo {
      height: 20px;
    }
  }

  & > .navs {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    & > .nav-item {
      margin-right: 10px;
      line-height: 24px;
      color: white;
    }
  }
`;

export default Navbar;
