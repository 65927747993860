import * as _User from './Reducers/User';
import * as _Lang from './Reducers/Lang';
import * as _Order from './Reducers/Order';
import * as _Product from './Reducers/Product';
import * as _AppState from './Reducers/AppState';
import { namedExportWithMemorizedOnce } from './Utils/FpUtil';
import ParseQuery from './Utils/ParseQuery';

let User = namedExportWithMemorizedOnce(_User);
let Lang = namedExportWithMemorizedOnce(_Lang);
let Order = namedExportWithMemorizedOnce(_Order);
let Product = namedExportWithMemorizedOnce(_Product);
let AppState = namedExportWithMemorizedOnce(_AppState);

export default {
  getQueryParams: ownProps => ParseQuery(ownProps.location.search),
  getLang: state => Lang.getLang(state.lang),
  getLoginUser: state => User.getLoginUser(state.user),
  getPatient: state => User.getPatient(state.user),
  getOrders: state => Order.getOrderList(state.order),
  getOrder: (state, id) => Order.getOrderDetailById(state.order, id),
  getItemById: (state, id) => Product.getItemById(state.product, id),
  getDoctors: (state) => AppState.getDoctors(state.appState),
};
